import React, { useEffect, useState } from "react";
import FilterIcon from "../../../assets/icons/filter.svg";
import searchIcon from "../../../assets/icons/search.svg";
import AddTemplateIcon from "../../../assets/icons/AddTemplateIcon.png";
import DataTable from "../../../components/common/DataTable/DataTable";
import FilterModal from "../../../components/common/FilterModal/FilterModal";
import { useNavigate } from "react-router-dom";
import "../EmailTemplate/EmailTemplate.css";
import EmailTemplateModal from "../../../components/EmailTemplateModal/EmailTemplateModal";
import { useGetEmailTemplatesQuery } from "../settingsSlice";
import toast, { Toaster } from "react-hot-toast";
import LoadingSpinner from "../../../components/common/LoadingSpinner/LoadingSpinner";

const EmailTemplate = () => {
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [itemCount, setItemCount] = useState(0);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [query, setQuery] = useState("");
  const [filters, setFilters] = useState([]);
  const [templateGuid, setEmailTemplateGuid] = useState("");

  const {
    data: emailTemplatesData,
    isLoading: emailTemplatesDataIsLoading,
    isFetching: emailTemplatesDataIsFetching,
    isSuccess: emailTemplatesDataIsSuccess,
    isError: emailTemplatesDataIsError,
    error: emailTemplatesDataError,
  } = useGetEmailTemplatesQuery({
    query,
    filters,
    page,
    pageSize,
  });

  useEffect(() => {
    if (emailTemplatesData) {
      const data = emailTemplatesData?.ids?.map(
        (id) => emailTemplatesData?.entities[id]
      );

      const templates = data[0];
      setPage(templates?.page);
      setItemCount(templates?.total);
      setEmailTemplates(templates?.items);
    }
    if (emailTemplatesDataIsError) {
      toast.error(emailTemplatesDataError?.data?.Message);
    }
  }, [emailTemplatesData, emailTemplatesDataIsError]);

  const emailTemplateDatacolumns = [
    {
      key: "Name",
      field: "name",
      headerText: "Name",
      alignment: "Left",
      template: (props) => (
        <div>
          <span className="name-field px-1">{props?.name}</span>
        </div>
      ),
    },
    {
      key: "Subject",
      field: "subject",
      headerText: "Subject",
      alignment: "Left",
      template: (props) => (
        <div>
          <span className="subject-field px-1">{props?.subject}</span>
        </div>
      ),
    },
  ];

  function closeModal() {
    setShowFilterModal(false);
  }

  const filterOptions = [
    { id: 1, name: "Name", type: "text" },
    { id: 2, name: "Subject", type: "text" },
  ];

  const handleSearch = (e) => {
    if (page !== 1) {
      setPage(1);
    }
    const searchText = e.target.value;
    setQuery(searchText.toLowerCase());
  };

  const handleRowSelect = (args) => {
    setShowEmailModal(true);
    setEmailTemplateGuid(args?.data?.guid);
  };

  const handleAddNewEmailTemplate = () => {
    setShowEmailModal(true);
    setEmailTemplateGuid("");
  };

  return (
    <div>
      <Toaster position="top right" />

      <div className="candicate-pipeline-content mt-2">
        <div className="row gap-2 justify-content-between align-items-center py-2 px-4">
          <div className="email-template-header">
            <div className="mt-2">
              Setup email templates to send emails to your intellimorph Talent
              candidates
            </div>
            <div>
              <button
                className="btn add-field-button"
                onClick={handleAddNewEmailTemplate}
              >
                <img
                  src={AddTemplateIcon}
                  alt="Icon"
                  width={28}
                  className="mb-1 pe-0"
                />
                Add new Template
              </button>
            </div>
          </div>
          <div className="col-12 col-md-2 p-0">
            <div className="d-flex">
              <div>
                <select
                  className="border-0 page-selector"
                  onChange={(e) => setPageSize(e.target.value)}
                >
                  <option value={10}>Display 10 Email Templates</option>
                  <option value={20}>Display 20 Email Templates</option>
                  <option value={50}>Display 50 Email Templates</option>
                  <option value={100}>Display 100 Email Templates</option>
                </select>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-5 border-2 d-flex justify-content-end py-3">
            <div style={{ position: "relative" }}>
              <div className="search-icon">
                <img src={searchIcon} alt="Search" />
              </div>
              <div className="px-1">
                <input
                  id="file-search-input"
                  type="text"
                  className="form-control custom-input w-100 pl-3"
                  placeholder="Search Email Template"
                  onChange={handleSearch}
                />
              </div>
            </div>
            <div>
              <div
                onClick={() => {
                  setShowFilterModal(true);
                }}
                className="search-filter-icon"
              >
                <img src={FilterIcon} alt="filter" />
              </div>
            </div>
          </div>
        </div>
        <FilterModal
          showModal={showFilterModal}
          closeModal={closeModal}
          filterOptions={filterOptions}
          confirmSubmit={setFilters}
        />
        <EmailTemplateModal
          templateGuid={templateGuid}
          showModal={showEmailModal}
          closeModal={setShowEmailModal}
        />
        <div className="row">
          <div>
            {emailTemplatesDataIsLoading && <LoadingSpinner />}
            {emailTemplatesDataIsSuccess ? (
              <DataTable
                data={emailTemplates}
                columns={emailTemplateDatacolumns}
                defaultPaging={false}
                setPage={setPage}
                pageSize={pageSize}
                totalRecords={itemCount}
                selectable={true}
                page={page}
                height={600}
                onRowSelected={handleRowSelect}
              />
            ) : (
              "Something Went wrong while fetching template data"
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailTemplate;
