import React, { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Link } from "react-router-dom";
import "./BreadcrumbsComponent.css";
import backWardIcon from "../../../assets/icons/lessthan.svg";
import notificationIcon from "../../../assets/images/BellIcon.png";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { useGetJobsQuery } from "../../../features/MyActions/MyActionsSlice";

export default function BreadcrumbsComponent({
  notificationCount,
  toggleNotificationModal,
}) {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [collapsed, setCollapsed] = useState(true);
  const browserParams = useParams();
  const urlSearchParams = new URLSearchParams(location.search);
  const jobGuid = urlSearchParams.get("jobGuid");
  const [total, setTotal] = useState(0);
  const [jobs, setJobs] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageSize, setPageSize] = useState(10);

  const toggleNavbar = () => setCollapsed(!collapsed);

  const capitalizeFunction = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  const capitalizeEachWordFunction = (text) => {
    let tempArray = text.split(" ");
    tempArray = tempArray.map((text) => capitalizeFunction(text));
    let newText = "";
    for (let i = 0; i < tempArray.length; i++) {
      newText = newText + tempArray[i] + " ";
    }
    return newText;
  };

  const {
    data: jobsData,
    isLoading: jobsDataIsLoading,
    isFetching: jobsDataIsFetching,
    isSuccess: jobsDataIsSuccess,
    isError: jobsDataIsError,
    error: jobsDataError,
  } = useGetJobsQuery({
    page: 1,
    pageSize: pageSize,
    statusGuid: "",
  });

  useEffect(() => {
    if (jobsData) {
      setJobs(jobsData.items);
      setTotal(jobsData.total);
    }
  }, [jobsData]);

  useEffect(() => {
    if (total) {
      setPageSize(total);
    }
  }, [total]);

  useEffect(() => {
    if (
      jobsData &&
      !jobGuid &&
      !browserParams.appliedJobGuid &&
      location.pathname.includes("job-candidates")
    ) {
      setSearchParams({ jobGuid: jobsData?.items[0]?.guid });
    }
  }, [jobGuid, jobsData, location, browserParams]);

  useEffect(() => {
    let crumbs = location.pathname.split("/").filter((crumb) => crumb !== "");

    if (crumbs.includes("job-candidates") && params.appliedJobGuid) {
      crumbs = crumbs.map(
        (crumb, index) => index !== 2 && decodeURIComponent(crumb)
      );
      crumbs = crumbs.filter((crumb) => crumb);
    }

    crumbs = crumbs.map((crumb, index) => {
      let pathname = "";
      for (let i = 0; i <= index; i++) {
        pathname = pathname + "/" + crumbs[i];
      }

      if (
        crumbs.includes("job-candidates") &&
        params.appliedJobGuid &&
        index === 1
      ) {
        pathname = crumbs[0] / params.name / params.appliedJobGuid;
      }

      if (crumb.includes("-")) {
        crumb = crumb.split("-");
        let tempCrumb = crumb[0];
        for (let index = 1; index < crumb.length; index++) {
          tempCrumb = tempCrumb + " " + capitalizeFunction(crumb[index]);
        }
        crumb = tempCrumb;
      }

      crumb = capitalizeFunction(crumb);

      if (crumb === "Activity" || crumb === "Admin") {
        return null;
      } else {
        return (
          <NavItem key={crumb} className="gap-1 mb-2 px-1">
            {index != 0 && (
              <img
                src={backWardIcon}
                width={25}
                height={25}
                alt="back"
                onClick={() => navigate(pathname)}
                style={{ cursor: "pointer" }}
              />
            )}
            <Link className="navbar-links" to={pathname}>
              {crumb}
            </Link>
          </NavItem>
        );
      }
    });

    setBreadcrumbs(crumbs);
  }, [location.pathname]);

  return (
    <Navbar color="faded" light>
      <NavbarToggler onClick={toggleNavbar} className="me-2 mb-2 d-md-none" />
      <Collapse isOpen={!collapsed} className="d-md-block" navbar>
        <Nav navbar className="bg-sm">
          <div className="d-md-flex">
            {breadcrumbs}
            <NavItem className="mb-sm-2">
              <div className="px-md-3">
                {location.pathname.includes("job-candidates") &&
                  !location.pathname.includes(browserParams.appliedJobGuid) && (
                    <select
                      className="border-0 page-selector"
                      onChange={(e) =>
                        setSearchParams({ jobGuid: e.target.value })
                      }
                    >
                      {jobs.map((job) => (
                        <option
                          defaultValue={job.guid === jobGuid}
                          key={job.guid}
                          value={job.guid}
                        >
                          {job.title}
                        </option>
                      ))}
                    </select>
                  )}
              </div>
            </NavItem>
            <NavItem className="ms-md-auto me-3">
              <div style={{ position: "relative" }}>
                <img
                  src={notificationIcon}
                  width={20}
                  alt="notification"
                  className="notification-icon"
                  onClick={toggleNotificationModal}
                />
                <div className="notification-count">
                  {notificationCount > 99 ? "99+" : notificationCount}
                </div>
              </div>
            </NavItem>
          </div>
        </Nav>
      </Collapse>
    </Navbar>
  );
}
