import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Page,
  Sort,
  DetailRow,
  PagerComponent,
  PagerDropDown,
  Pager,
} from "@syncfusion/ej2-react-grids";
import { L10n } from "@syncfusion/ej2-base";
import "./DataTable.css";
import { useNavigate } from "react-router-dom";
import { nanoid } from "@reduxjs/toolkit";

L10n.load({
  "en-US": {
    pager: {
      itemsPerPage: "",
      currentPageInfo: "",
      totalItemsInfo: "Showing {1} to {2} of {0}",
    },
  },
});

const DataTable = ({
  data,
  columns,
  grid,
  setPage,
  pageSize,
  setPageSize,
  totalRecords,
  defaultPaging = true,
  isDetailTemplate = false,
  selectable = false,
  navigateUrl,
  totalPages,
  page,
  onRowSelected,
  height = "auto",
  tableName,
  dataKey,
}) => {
  const navigate = useNavigate();
  Pager.Inject(PagerDropDown);

  const handlePagerClick = (args) => {
    setPage(args.currentPage);
    // if ("pageSize" in args.newProp) {
    //   setPageSize(args.newProp.pageSize);
    // }
  };

  const handleCellSelected = (args) => {
    if (selectable) {
      navigate(navigateUrl);
    }
  };

  const tableColumns =
    columns && columns.length > 0
      ? columns.map((column) => (
          <ColumnDirective
            key={column.key}
            field={column.field}
            headerText={column.headerText}
            width={column.width}
            textAlign={column.alignment}
            template={column.template}
            customAttributes={column.customAttributes}
          />
        ))
      : null;

  const handleRowHover = () => {
    const gridRows = document.querySelectorAll(".e-row");

    gridRows.forEach((row) => {
      row.addEventListener("mouseover", (e) => {
        const targetRow = e.currentTarget;
        const rowIndex = targetRow.getAttribute("data-rowindex");

        grid.current.detailRowModule.expand(rowIndex);
      });

      row.addEventListener("mouseout", (e) => {
        const targetRow = e.currentTarget;
        const rowIndex = targetRow.getAttribute("data-rowindex");

        grid.current.detailRowModule.collapse(rowIndex);
      });
    });
  };

  const onGridCreated = () => {
    if (isDetailTemplate) {
      handleRowHover();
    }
  };

  return (
    <>
      <div style={{ overflow: "auto" }}>
        <GridComponent
          key={dataKey}
          ref={grid}
          cssClass={tableName}
          className="data-table"
          dataSource={data}
          allowPaging={defaultPaging}
          allowSorting={true}
          allowTextWrap={true}
          height={height}
          enableAdaptiveUI={true}
          locale="en-US"
          created={onGridCreated}
          style={{ height: "auto" }}
          selectionSettings={{ mode: "Both", type: "Single" }}
          cellSelected={handleCellSelected}
          queryCellInfo={(args) => {
            if (args.column.field && selectable) {
              args.cell.classList.add("custom-pointer-cursor");
            }
          }}
          rowSelected={onRowSelected}
        >
          <ColumnsDirective>{tableColumns}</ColumnsDirective>
          <Inject services={[Sort, Page, DetailRow]} />
        </GridComponent>
      </div>

      {!defaultPaging && (
        <PagerComponent
          pageSize={pageSize}
          totalRecordsCount={totalRecords}
          currentPage={page}
          click={handlePagerClick}
        />
      )}
    </>
  );
};
export default DataTable;
